.webpage-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1rem 5%; */
  background: linear-gradient(90deg, #68cd8f, #14a2b1);
  color: white;
  height: 80px;
  transition: all 0.3s ease;
}

/* Logo styles */
.webpage-header-left {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  cursor: pointer;
  flex-shrink: 0; /* Prevents logo from shrinking */
}

/* Navigation container */
.webpage-header-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes navigation to the right */
}

/* Navigation menu */
.header-menu {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header-menu-item {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.header-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.header-menu-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}


.webpage-header-left {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  cursor: pointer;
  flex-shrink: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 12px; /* Adjust for rounded corners */
  padding: 2px; /* Adjust padding as needed */
  margin-right: 10px; /* Space between the background and text */
  margin-left: 40px;
}

.logo-image {
  height: 50px; /* Adjust as needed */
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}
/* Mobile menu icon */
.mobile-menu-icon {
  display: none; /* Initially hidden */
  font-size: 24px;
  cursor: pointer;
  padding: 0.5rem;
  color: white;
  background: none;
  border: none;
  transition: all 0.3s ease;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 0;
}

/* Animation for mobile menu */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobile-menu-open {
  animation: slideDown 0.3s ease forwards;
}

/* Scrolled header styles */
.header-scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #5ab57d, #128799);
}

/* Add this new rule at the end of the file */
:root {
  --header-height: 80px;
}

body {
  padding-top: var(--header-height);
}

/* Responsive Design */
@media (max-width: 1000px) {
  .webpage-header {
    padding: 1rem 1.5rem;
  }

  .mobile-menu-icon {
    display: flex; /* Only show on mobile */
    margin-left: 1rem;
  }

  .header-menu {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: linear-gradient(90deg, #68cd8f, #14a2b1);
    padding: 1rem 0;
    gap: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .header-menu.mobile-menu-open {
    display: flex;
  }

  .header-menu-item {
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-radius: 0;
  }

  .header-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: none;
  }

  .logo-container {
    margin-left: 0;
  }

  .logo-image {
    height: 40px; /* Slightly smaller logo for mobile */
  }

  .webpage-header-left {
    font-size: 20px; /* Smaller font size for mobile */
  }

  /* Ensure the mobile menu button is properly aligned */
  .webpage-header-right {
    display: flex;
    align-items: center;
    height: 100%; /* Ensure full height */
  }

  /* Improve mobile menu animation */
  .mobile-menu-open {
    animation: slideDown 0.3s ease forwards;
  }

  /* Ensure the header stays on top */
  .webpage-header {
    z-index: 1000;
  }
}

/* Additional mobile adjustments for smaller screens */
@media (max-width: 480px) {
  .webpage-header {
    padding: 0.5rem 1rem;
  }

  .logo-image {
    height: 35px;
  }

  .webpage-header-left {
    font-size: 18px;
  }

  .logo-container {
    padding: 2px;
    margin-right: 8px;
  }
}