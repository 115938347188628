body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add these styles to your global CSS file */
html, body {
  height: 100%;
}

#root {
  min-height: 100%;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}