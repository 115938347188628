.f{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 8999;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    font-weight: 500;   
    box-shadow: 0 1px 6px rgba(169, 188, 218, .65);
    /* background: linear-gradient(90deg, #14A2B1, #68CD8F); */
    /* color: white; */
}

.f-left{
    font-size: 12px;
}

.f-right{
    font-size: 14px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.f-right:hover{
    cursor: pointer;
}

.f-right-content{
    padding: 4px 20px;
}

.webpage-footer {
  background: linear-gradient(90deg, #68cd8f, #14a2b1);
  color: white;
  padding: 1.5rem 0;
  margin-top: auto;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.footer-link:hover {
  opacity: 1;
  text-decoration: underline;
}

.footer-copyright {
  font-size: 0.9rem;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}