.privacy-policy-container {
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
  background-color: var(--background-color);
}

.privacy-policy-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
  color: var(--text-color);
}

.privacy-policy-content h1 {
  margin-bottom: 1.5rem;
}

.privacy-policy-content h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy-content p,
.privacy-policy-content ul {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.privacy-policy-content ul {
  padding-left: 2rem;
}

.privacy-policy-content a {
  color: var(--primary-color);
  text-decoration: none;
}

.privacy-policy-content a:hover {
  text-decoration: underline;
}

.privacy-policy-content strong {
  color: #333;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 1rem;
  }

  .privacy-policy-content {
    padding: 1.5rem;
  }

  .privacy-policy-content h1 {
    font-size: 2rem;
  }

  .privacy-policy-content h2 {
    font-size: 1.5rem;
  }
} 